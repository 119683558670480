import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import PlatformMenu from './PlatformMenu';
import UseCasesMenu from './UseCasesMenu';
import StartBusinessMenu from './StartBusinessMenu';
import ScaleBusinessMenu from './ScaleBusinessMenu';
import CommunityMenu from './CommunityMenu';
import SolutionsMenu from './Solutions';
import FreeToolsMenu from './FreeTools';
import EcommerceSuite from './EcommerceSuite';
import { NavMenuType } from '@/types/layout';
import './index.less';

interface IProps {
  activeMenu: NavMenuType;
  closeActiveMenu: () => void;
}

export default function HeaderMenu({ activeMenu, closeActiveMenu }: IProps) {
  return (
    <>
      <div
        className={classNames('nav-menu-desktop-mask', { active: !_.isEmpty(activeMenu) })}
        onMouseOver={(e: any) => {
          e.stopPropagation();
          if (e?.target?.className?.includes && e?.target?.className?.includes('nav-menu-desktop-mask')) {
            closeActiveMenu();
          }
        }}
      ></div>
      <div className={classNames('nav-menu-desktop', { active: !_.isEmpty(activeMenu) })}>
        {activeMenu === NavMenuType.PLATFORM && <PlatformMenu closeActiveMenu={closeActiveMenu} />}
        {activeMenu === NavMenuType.USE_CASES && <UseCasesMenu closeActiveMenu={closeActiveMenu} />}
        {activeMenu === NavMenuType.START_BUSINESS && <StartBusinessMenu closeActiveMenu={closeActiveMenu} />}
        {activeMenu === NavMenuType.E_COMMERCE_SUITE && <EcommerceSuite closeActiveMenu={closeActiveMenu} />}
        {activeMenu === NavMenuType.FREE_TOOLS && <FreeToolsMenu closeActiveMenu={closeActiveMenu} />}
        {activeMenu === NavMenuType.SOLUTIONS && <SolutionsMenu closeActiveMenu={closeActiveMenu} />}
        {activeMenu === NavMenuType.RESOURCES && <CommunityMenu closeActiveMenu={closeActiveMenu} />}
        {activeMenu === NavMenuType.SCALE_BUSINESS && <ScaleBusinessMenu closeActiveMenu={closeActiveMenu} />}
      </div>
    </>
  );
}
