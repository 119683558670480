import Image from 'next/image';
import cN from 'classnames';
import Link from 'next/link';
import { MenuListProps } from '@/shared/types/menu';
import './index.less';

interface IProps {
  lists: MenuListProps[];
  size?: number;
  className?: string;
}
const NavMenuListItem = ({ lists, size = 120, className }: IProps) => {
  return (
    <ul className={cN('pietra-header-menu-list', className)}>
      {lists.map((item) => {
        return (
          <li className={item.class} key={item.class}>
            <Link href={item.href} target={item.target}>
              <div className="overview">
                {item.titleImg && <Image src={item.titleImg} width={53} height={16} alt={item.title || item.desc} />}
                <span className="title">{item.title}</span>
                {item.desc && <p className="description">{item.desc}</p>}
              </div>
              {item.imgSrc && <Image src={item.imgSrc} width={size} height={size} alt={item.title || item.desc} />}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
export default NavMenuListItem;
