import React from 'react';
import classNames from 'classnames';
import './index.less';

interface IProps {
  activePillarIndex: number;
}

export default function AnimatedPillarsIcon({ activePillarIndex }: IProps) {
  const stones = ['1', '2', '3', '4'];

  return (
    <div className={classNames('animated-pillars', `active-stone-${activePillarIndex}`)}>
      {stones.map((filename: string, i: number) => (
        <div className="pietra-stone-container" key={filename}>
          <div
            className={classNames('stone', `stone-${i.toString()}`, {
              'is-hovered': activePillarIndex !== null,
              active: activePillarIndex === i,
            })}
          />
        </div>
      ))}
    </div>
  );
}
