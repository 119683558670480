import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import Image from 'next/image';
import { PlatformMenuApp } from '@/types/layout';
import { useRouter } from 'next/router';
import { STATIC_FILE_URL } from '@/shared/constants';
import './index.less';

interface IProps {
  apps: PlatformMenuApp[];
  closeActiveMenu: () => void;
  darkMode?: boolean;
}

export default function FreePietraApps({ apps, closeActiveMenu, darkMode }: IProps) {
  const router = useRouter();

  return (
    <div className={classNames('free-pietra-apps', { 'dark-mode': darkMode })}>
      <div className="title-container">
        <Image
          src={
            darkMode
              ? `${STATIC_FILE_URL}/7a785c6635df7cdba4a11910d091a556.svg`
              : `${STATIC_FILE_URL}/535452cdd68393a66f565b82169d6c78.svg`
          }
          width={42}
          height={28}
          alt="Free Software"
        />
        <span className="title">Free Pietra Apps</span>
      </div>

      <div className="platform-features">
        {apps.map((app: PlatformMenuApp) => {
          return (
            <div
              className="platform-feature"
              key={app.title}
              onClick={() => {
                router.push(app.path);
                closeActiveMenu();
              }}
            >
              <div className="icon-container">
                <Image
                  src={
                    darkMode
                      ? `${STATIC_FILE_URL}/e3f8e8df353e346fbbc3ce6b784fd7ed.svg`
                      : `${STATIC_FILE_URL}/46a0624508320c000115abd29c0d55b8.svg`
                  }
                  width={10}
                  height={10}
                  alt="Pietra Plus"
                />
              </div>
              <div className="text-container">
                <span className="title">{app.title}</span>
                <div className="description">
                  {app.info.map((line: string) => (
                    <span key={line}>{line}</span>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
