import Link from 'next/link';
import { PietraLogoIcon, PietraLogoIcons } from '@/components/Common/Icons';
import './index.less';

interface IProps {
  closeActiveMenu: () => void;
}

const SolutionsMenu = ({ closeActiveMenu }: IProps) => {
  return (
    <div className="solutions-menu-container" onClick={closeActiveMenu}>
      <div className="solutions-menu-content">
        <div className="solutions-menu-popular-container">
          <Link href={'/new-brands'}>
            <div className="solutions-menu-popular-icon">
              <PietraLogoIcon />
            </div>
            <div className="solutions-menu-popular-content">
              <h4>For solopreneuers</h4>
              <p>For first timers, see how Pietra gives you everything you need to build your business. </p>
            </div>
          </Link>
        </div>
        <div className="solutions-menu-popular-container">
          <Link href={'/existing-brands'}>
            <div className="solutions-menu-popular-icon">
              <PietraLogoIcons />
            </div>
            <div className="solutions-menu-popular-content">
              <h4>For teams</h4>
              <p>
                For teams, see how Pietra&apos;s platform grows profits while making it easier to manage operations.{' '}
              </p>
            </div>
          </Link>
        </div>
        <div className="solutions-menu-popular-container">
          <div className="solutions-menu-popular-content">
            <h4>By vertical</h4>
            <ul>
              <li>
                <Link href="/l/open-your-pietra-storefront">Start your brand</Link>
              </li>
              <li>
                <Link href="/l/start-a-clothing-line">Build a clothing line</Link>
              </li>
              <li>
                <Link href="/l/start-a-athleisure-line">Build an athleisure line</Link>
              </li>
              <li>
                <Link href="/l/start-a-candle-line">Build a candle line</Link>
              </li>
              <li>
                <Link href="/l/start-a-jewelry-line">Build a jewelry line</Link>
              </li>
              <li>
                <Link href="/l/start-a-skincare-line">Build a skincare line</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SolutionsMenu;
