import { COMMUNITIY_MENU_SERVICE, COMMUNITIY_MENU_SOCIAL_ACCOUNT } from '@/shared/constants/nav-constants';
import NavMenuListItem from '@/components/Common/Layout/HeaderMenu/NavMenuListItem';
import Link from 'next/link';
import { useIntercom } from 'react-use-intercom';
import { BEGINNER_WORKSHOP_LINK } from '@/shared/constants/sourcing-marketplace';
import './index.less';

interface IProps {
  closeActiveMenu: () => void;
}

const CommunityMenu = ({ closeActiveMenu }: IProps) => {
  const { boot } = useIntercom();

  return (
    <div className="community-menu-container" onClick={closeActiveMenu}>
      <div className="community-menu-content">
        <div className="community-list-content">
          <NavMenuListItem lists={COMMUNITIY_MENU_SERVICE} />
        </div>
        <div className="community-list-content">
          <h4>Learn</h4>
          <ul className="community-menu-list">
            <li>
              <Link href="/blog">Blog</Link>
            </li>
            <li>
              <Link href={BEGINNER_WORKSHOP_LINK} target="_blank">
                Webinars
              </Link>
            </li>
          </ul>
        </div>
        <div className="community-list-content">
          <h4>Connect</h4>
          <ul className="community-menu-list">
            <li>
              <Link href="https://pietra.xyz/community" target="_blank">
                Community
              </Link>
            </li>
            <li>
              <Link href="https://www.instagram.com/shoppietra" target="_blank">
                Instagram
              </Link>
            </li>
            <li>
              <Link href="https://www.tiktok.com/@shoppietra" target="_blank">
                TikTok
              </Link>
            </li>
            <li>
              <Link href="https://pietra.xyz/affiliate" target="_blank">
                Affiliate program
              </Link>
            </li>
            <li>
              <Link href="/pietra-partners-new">Become a supplier</Link>
            </li>
            <li>
              <Link href="/careers">Careers</Link>
            </li>
          </ul>
        </div>
        <div className="community-list-content">
          <h4>Support</h4>
          <ul className="community-menu-list">
            <li>
              <Link href="https://help.pietrastudio.com/en/" target="_blank">
                Help center
              </Link>
            </li>
            <li>
              <a
                onClick={() => {
                  boot();
                }}
              >
                Contact us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default CommunityMenu;
