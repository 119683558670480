import Link from 'next/link';
import {
  ProductFilled,
  MaginWandFilled,
  PolyMailerFilled,
  NotesPaperFilled,
  WindmillFilled,
  PackagingFilled,
  RetouchStickerFilled,
  PrintTshirtFilled,
  FreelancerFilled,
  WarehouseStorageOutlined,
  AnalyticsGraphBarOutlined,
  WarehouseStorageFilled,
  EshopFilled,
  ShoppingVoucherMailFilled,
  AdvertisingMegaphoneFilled,
  AnalyticsGraphBarFilled,
} from 'src/components/Common/Icons';
import './index.less';

interface IProps {
  closeActiveMenu: () => void;
  isMobile?: boolean;
}

const EcommerceSuite = ({ closeActiveMenu, isMobile }: IProps) => {
  return (
    <div className="e-commerce-suite-menu-container" onClick={closeActiveMenu}>
      <div className="e-commerce-suite-menu-content">
        <div className="e-commerce-suite-menus-container">
          <div className="e-commerce-suite-menus-group" style={{ '--private-group-flex': 2 } as any}>
            <div className="e-commerce-suite-menus-group-body">
              <div className="e-commerce-suite-menus">
                <h4>Design</h4>
                <ul>
                  <li>
                    <Link href={'/platform/design-studio'}>
                      <MaginWandFilled />
                      Visualize ideas with AI
                    </Link>
                  </li>
                  <li>
                    <Link href={'/platform/sourcing-and-production/packaging/polymailers'}>
                      <PolyMailerFilled />
                      Design poly mailers
                    </Link>
                  </li>
                  <li>
                    <Link href={'/platform/sourcing-and-production/packaging/notecards'}>
                      <NotesPaperFilled />
                      Design notecards
                    </Link>
                  </li>
                  <li>
                    <Link href={'/platform/sourcing-and-production/packaging/tissue-paper'}>
                      <WindmillFilled />
                      Design tissue paper
                    </Link>
                  </li>
                  <li>
                    <Link href={'/platform/sourcing-and-production/packaging/shipping-boxes'}>
                      <PackagingFilled />
                      Design shipping boxes
                    </Link>
                  </li>
                  <li>
                    <Link href={'/platform/sourcing-and-production/packaging/stickers'}>
                      <RetouchStickerFilled />
                      Design stickers
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="e-commerce-suite-menus sourcing">
                <h4>Source</h4>
                <ul>
                  <li>
                    <Link href={'/platform/sourcing-and-production/products'}>
                      <ProductFilled />
                      Create custom products
                    </Link>
                  </li>
                  <li>
                    <Link href={'/platform/print-on-demand'}>
                      <PrintTshirtFilled />
                      Create made to order merch
                    </Link>
                  </li>
                  <li>
                    <Link href={'/platform/sourcing-and-production/packaging'}>
                      <PackagingFilled />
                      Create custom packaging
                    </Link>
                  </li>
                  <li>
                    <Link href={'/platform/sourcing-and-production/freelancers'}>
                      <FreelancerFilled />
                      Find business & creatives services
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="e-commerce-suite-menus-group">
            <div className="e-commerce-suite-menus-group-body">
              <div className="e-commerce-suite-menus">
                <h4>Fulfill</h4>
                <ul>
                  <li className="fulfillment">
                    <Link href={'/platform/logistics-and-fulfillment'}>
                      <WarehouseStorageFilled />
                      Store and ship products
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="e-commerce-suite-menus-group">
            <div className="e-commerce-suite-menus-group-body">
              <div className="e-commerce-suite-menus grow">
                <h4>Sell</h4>
                <ul>
                  <li>
                    <Link href={'/platform/print-on-demand'}>
                      <EshopFilled />
                      Set up an online storefront
                    </Link>
                  </li>
                </ul>
                <h4>Market</h4>
                <ul>
                  <li>
                    <Link href={'/platform/email-capture'}>
                      <ShoppingVoucherMailFilled />
                      Build an email list automatically
                    </Link>
                  </li>
                  <li>
                    <Link href={'/platform/affiliate-marketing'}>
                      <AdvertisingMegaphoneFilled />
                      Manage an affiliate program
                    </Link>
                  </li>
                  <li>
                    <Link href={'/platform/analytics'}>
                      <AnalyticsGraphBarFilled />
                      Leverage powerful analytics
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EcommerceSuite;
