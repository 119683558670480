import React from 'react';
import Link from 'next/link';
import { NavCaseStudy } from '@/types/layout';
import Image from 'next/image';
import classNames from 'classnames';
import './index.less';

interface IProps {
  caseStudy: NavCaseStudy;
  hasWhiteText: boolean;
}

export default function NavMenuCaseStudy({ caseStudy, hasWhiteText }: IProps) {
  return (
    <Link href={caseStudy.link} target="_blank" className="nav-menu-case-study-link">
      <div className={classNames('nav-menu-case-study', { 'has-white-text': hasWhiteText })}>
        <div className="top">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={caseStudy.image} alt={caseStudy.brand} className="image" />
          <div className="info">
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src={caseStudy.logo} alt={caseStudy.brand} className="brand-image" />
            <span className="description">{caseStudy.description}</span>
          </div>
        </div>
        <div className="bottom">
          <span className="read-more">Read More</span>
          <Image className="arrow" width={20} height={20} src="/images/arrow_up.svg" alt="Dropdown arrow" />
        </div>
      </div>
    </Link>
  );
}
