export enum LinkTarget {
  Self = '_self',
  Blank = '_blank',
  Parent = '_parent',
  Top = '_top',
}

export interface MenuListProps {
  titleImg?: string;
  title?: string;
  desc?: string;
  imgSrc?: string;
  href: string;
  class?: string;
  target?: LinkTarget;
}
