import { FREE_TOOLS, COMMUNITIY_MENU_SERVICE, COMMUNITIY_MENU_SOCIAL_ACCOUNT } from '@/shared/constants/nav-constants';
import NavMenuListItem from '@/components/Common/Layout/HeaderMenu/NavMenuListItem';
import './index.less';

interface IProps {
  closeActiveMenu: () => void;
}

const FreeToolsMenu = ({ closeActiveMenu }: IProps) => {
  return (
    <>
      <div className="free-tools-menu-container" onClick={closeActiveMenu}>
        <div className="free-tools-menu-content">
          <h3>Free tools</h3>
          <NavMenuListItem lists={FREE_TOOLS} />
        </div>
      </div>
      <div className="community-menu-container">
        <div className="community-menu-content" onClick={closeActiveMenu}>
          <h3>Welcome to the Pietra family</h3>
          <div className="community-menu-list">
            <div className="community-list-content">
              <NavMenuListItem lists={COMMUNITIY_MENU_SERVICE} />
            </div>
            <div className="community-list-icon">
              <NavMenuListItem lists={COMMUNITIY_MENU_SOCIAL_ACCOUNT} size={24} className="community-social-media" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FreeToolsMenu;
