import React, { useCallback, useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import Image from 'next/image';
import { Button, Drawer, Dropdown } from 'antd';
import { useCreateAccountProvider } from '@/lib/context';
import { supplierPortalLink, webToolsLink } from '@/lib/service';
import './index.less';
import { MOBILE_MENU } from '@/shared/constants/nav-constants';
import StartBusinessMenu from '../../HeaderMenu/StartBusinessMenu';
import CommunityMenu from '../../HeaderMenu/CommunityMenu';
import FreeToolsMenu from '../../HeaderMenu/FreeTools';
import SolutionsMenu from '../../HeaderMenu/Solutions';
import EcommerceSuite from '../../HeaderMenu/EcommerceSuite';
import ScaleBusinessMenu from '../../HeaderMenu/ScaleBusinessMenu';
import { MobileMenuProps, NavMenuType } from '@/types/layout';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';

interface IProps {
  openLeftMenuDrawer: boolean;
  openLeftMenuDrawerFn: () => void;
  goToPreviewFlow: () => void;
  breakpoint?: number;
}

export default function MobileHeader({
  breakpoint,
  openLeftMenuDrawer,
  openLeftMenuDrawerFn,
  goToPreviewFlow,
}: IProps) {
  const [activeMenu, setActiveMenu] = useState<NavMenuType>(null);
  const { showSignupDialog } = useCreateAccountProvider();

  const loginDropdown = [
    {
      key: '1',
      label: (
        <Link href={webToolsLink()} target="_blank">
          Sign in as a Brand
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link href={supplierPortalLink()} target="_blank">
          Sign in as a Supplier
        </Link>
      ),
    },
  ];

  const closeActiveMenu = useCallback(() => {
    openLeftMenuDrawerFn();
    setActiveMenu(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = useCallback(
    (type: NavMenuType) => {
      if (activeMenu === type) {
        setActiveMenu(null);
      } else {
        setActiveMenu(type ?? null);
      }
    },
    [activeMenu],
  );

  return (
    <div className="pietra-header-container mobile">
      <Link key="pietra-logo" href="/" className="logo">
        <Image
          className="pietra-logo"
          src="/images/header/small-logo.png"
          width={76}
          height={20}
          alt="Pietra"
          title="Pietra"
          loading="eager"
        />
      </Link>
      <div className="mobile-menu-left">
        <Dropdown
          getPopupContainer={(triggerNode) => document.querySelector('#loginRef') ?? document.body}
          menu={{
            items: loginDropdown,
          }}
        >
          <a className="active" onClick={(e) => e.preventDefault()} id="loginRef">
            <span>Sign in</span>
            <div className="dropdown-arrow" />
          </a>
        </Dropdown>
        {breakpoint >= 576 && (
          <>
            <div className="nav-button demo" onClick={goToPreviewFlow} key="preview-pietra">
              <span
                className="preview-pietra"
                onMouseEnter={(e) => {
                  e.preventDefault();
                  setActiveMenu(null);
                }}
              >
                Book a demo
              </span>
            </div>
            <span className="create-account-btn" onClick={showSignupDialog}>
              Start for free
            </span>
          </>
        )}
        <div className="left-menu-drawer-btn" onClick={openLeftMenuDrawerFn}>
          <MenuOutlined />
        </div>
      </div>
      {typeof window === 'object' && (
        <Drawer
          title={
            <Link key="pietra-logo" href="/" className="logo">
              <Image
                className="pietra-logo"
                src="/images/header/small-logo.png"
                width={76}
                height={20}
                alt="Pietra"
                title="Pietra"
                loading="eager"
              />
            </Link>
          }
          closable={false}
          extra={<Button type="text" icon={<CloseOutlined />} onClick={closeActiveMenu} shape="circle" />}
          placement="left"
          width={'100vw'}
          closeIcon={true}
          rootClassName="left-menu-drawer"
          onClose={closeActiveMenu}
          open={openLeftMenuDrawer}
          getContainer={document.getElementById('pietra-layout-root') ?? document.body}
        >
          <div className={classNames('body', { 'submenu-active': !!activeMenu })}>
            {MOBILE_MENU.map(({ title, type, href, target }: MobileMenuProps, index) => (
              <div className="menu-item" key={`link-item-${index}`}>
                {type === NavMenuType.LINK ? (
                  <Link className="menu-item-link" href={href} target={target ?? null} onClick={closeActiveMenu}>
                    {title}
                  </Link>
                ) : (
                  <div
                    className={classNames('menu-item-dropdown', { active: activeMenu === type })}
                    onClick={() => handleClick(type)}
                  >
                    <div className="dropdown-title">
                      <span className="title">{title}</span>
                      <Image
                        className="dropdown-arrow"
                        width={20}
                        height={20}
                        src="/images/arrow_up.svg"
                        alt="Dropdown arrow"
                      />
                    </div>
                    <div className="dropdown-menu">
                      {activeMenu === NavMenuType.START_BUSINESS && (
                        <StartBusinessMenu closeActiveMenu={closeActiveMenu} />
                      )}
                      {activeMenu === NavMenuType.COMMUNITY && <CommunityMenu closeActiveMenu={closeActiveMenu} />}
                      {activeMenu === NavMenuType.SCALE_BUSINESS && (
                        <ScaleBusinessMenu closeActiveMenu={closeActiveMenu} />
                      )}
                      {activeMenu === NavMenuType.E_COMMERCE_SUITE && (
                        <EcommerceSuite closeActiveMenu={closeActiveMenu} isMobile={true} />
                      )}
                      {activeMenu === NavMenuType.SOLUTIONS && <SolutionsMenu closeActiveMenu={closeActiveMenu} />}
                      {activeMenu === NavMenuType.FREE_TOOLS && <FreeToolsMenu closeActiveMenu={closeActiveMenu} />}
                      {activeMenu === NavMenuType.RESOURCES && <CommunityMenu closeActiveMenu={closeActiveMenu} />}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="footer">
            <div className="content-container">
              <Dropdown
                placement="top"
                getPopupContainer={(triggerNode) => document.querySelector('#signInDropdown') ?? document.body}
                menu={{
                  items: loginDropdown,
                }}
              >
                <div className="dropdown-title" id="signInDropdown">
                  <span className="title">Sign in</span>
                  <Image
                    className="dropdown-arrow"
                    width={20}
                    height={20}
                    src="/images/arrow_up.svg"
                    alt="Dropdown arrow"
                  />
                </div>
              </Dropdown>

              <div className="space">
                <div className="preview-pietra" onClick={() => goToPreviewFlow()}>
                  <span>Book a demo</span>
                </div>
                <div
                  className="create-free-account"
                  onClick={() => {
                    showSignupDialog();
                    setActiveMenu(null);
                  }}
                >
                  <span>Create free account</span>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      )}
    </div>
  );
}
