export enum NavMenuType {
  PLATFORM = 'PLATFORM',
  USE_CASES = 'USE_CASES',
  START_BUSINESS = 'START_BUSINESS',
  SCALE_BUSINESS = 'SCALE_BUSINESS',
  COMMUNITY = 'COMMUNITY',
  LINK = 'LINK',
  E_COMMERCE_SUITE = 'E_COMMERCE_SUITE',
  FREE_TOOLS = 'FREE_TOOLS',
  SOLUTIONS = 'SOLUTIONS',
  RESOURCES = 'RESOURCES',
}

export interface PlatformMenuApp {
  title: string;
  info: string[];
  path: string;
}

export interface PlatformMenu {
  apps: PlatformMenuApp[];
  pillars: NavMenuItem[];
}

export interface NavMenuItem {
  path: string;
  title: string;
  description: string;
  class: string;
  icon: string;
  hoverIcon?: string;
  isAnimatedWand?: boolean;
}

export interface UseCasesMenuSection {
  title: string;
  items: NavMenuItem[];
}

export interface NavCaseStudy {
  brand: string;
  link: string;
  image: string;
  logo: string;
  description: string;
}
export interface UseCasesMenu {
  caseStudy: NavCaseStudy;
  sections: UseCasesMenuSection[];
}
export interface DropdownMenu {
  platform: PlatformMenu;
  useCases: UseCasesMenu;
}

export interface MobileNavLink {
  title: string;
  path?: string;
  target?: string;
  menuType?: NavMenuType;
  submenu?: {
    title: string;
    items: NavMenuItem[];
    caseStudy?: NavCaseStudy;
    apps?: PlatformMenuApp[];
  };
}

export interface MobileMenuProps {
  title: string;
  type: NavMenuType;
  href?: string;
  target?: string;
}
