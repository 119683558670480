import {
  SCALE_BUSINESS_MENU_APP,
  SCALE_BUSINESS_MENU_ARRAE,
  SCALE_BUSINESS_MENU_GROWTH,
  START_BUSINESS_MENU_APP,
  START_BUSINESS_MENU,
} from '@/shared/constants/nav-constants';

import NavMenuListItem from '@/components/Common/Layout/HeaderMenu/NavMenuListItem';
import './index.less';

interface IProps {
  closeActiveMenu: () => void;
}

const StartBusinessMenu = ({ closeActiveMenu }: IProps) => {
  return (
    <div className="start-business-menu-container">
      <div className="start-business-menu-content" onClick={closeActiveMenu}>
        <div className="start-right-container">
          <h3>Free tools for you</h3>
          <NavMenuListItem className="start-small-icon" lists={START_BUSINESS_MENU_APP} />
        </div>
        <div className="start-left-container">
          <h3>One platform to get you started</h3>
          <NavMenuListItem lists={START_BUSINESS_MENU} size={74} />
        </div>
      </div>
    </div>
  );
};
export default StartBusinessMenu;
