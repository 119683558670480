import {
  SCALE_BUSINESS_MENU_APP,
  SCALE_BUSINESS_MENU_ARRAE,
  SCALE_BUSINESS_MENU_GROWTH,
} from '@/shared/constants/nav-constants';
import NavMenuListItem from '@/components/Common/Layout/HeaderMenu/NavMenuListItem';
import './index.less';

interface IProps {
  closeActiveMenu: () => void;
}

const ScaleBusinessMenu = ({ closeActiveMenu }: IProps) => {
  return (
    <div className="scale-business-menu-container">
      <div className="scale-business-menu-content" onClick={closeActiveMenu}>
        <div className="scale-left-container">
          <h3>Supercharge your growth</h3>
          <NavMenuListItem lists={SCALE_BUSINESS_MENU_GROWTH} size={74} />
        </div>
        <div className="scale-right-container">
          <h3>Free Pietra Apps</h3>
          <NavMenuListItem className="scale-small-icon" lists={SCALE_BUSINESS_MENU_APP} />
          <NavMenuListItem className="scale-business-menu-arrae" lists={SCALE_BUSINESS_MENU_ARRAE} size={88} />
        </div>
      </div>
    </div>
  );
};
export default ScaleBusinessMenu;
